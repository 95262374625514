import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Booking from '@/store/entities/bookings/booking';
import BookingProduct from '@/store/entities/bookings/bookingproduct';

interface BookingState extends ListState<Booking> {
    editModel: Booking;
    viewModel: Booking;
    products: Array<BookingProduct>;
}
class BookingModule extends ListModule<BookingState, any, Booking>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<Booking>(),
        loading: false,
        editModel: new Booking(),
        viewModel: new Booking(),
        activeList: new Array<Booking>(),
        products: new Array<BookingProduct>(),
        path: 'bookings'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async get(context: ActionContext<BookingState, any>, payload: any) {
            if (payload.id == undefined) return {};
            context.state.loading = true;
            let reponse = await Ajax.get('/bookings/get/' + payload.id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            let item = reponse.data.result as Booking;
            context.state.loading = false;
            return item;
        },
        async create(context: ActionContext<BookingState, any>, payload: any) {
            context.state.loading = true;

            let reponse = await Ajax.post('/bookings', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            if (reponse.data.result) {
                context.state.editModel = Object.assign({});
                context.state.editModel.products = new Array<BookingProduct>();
                context.state.products = context.state.editModel.products;
                localStorage.booking = JSON.stringify(context.state.editModel);
            }

            context.state.loading = false;
            return reponse.data.result;
        },
        async getBooking(context: ActionContext<BookingState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/bookings', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editModel = reponse.data.result;
            return reponse.data.result;
        },
        async getDayBookings(context: ActionContext<BookingState, any>, payload: any) {
            context.state.loading = true;
            context.state.list = [];
            let reponse = await Ajax.get('/bookings/daily', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.list = reponse.data.result;
            context.state.loading = false;
            return reponse.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        initCache(state: BookingState, model: Booking) {
            if (localStorage.booking != null) {
                let booking = JSON.parse(localStorage.booking);
                state.editModel = Util.extend(true, {}, booking);
            } else {
                state.editModel = Object.assign({});
                state.editModel.products = new Array<BookingProduct>();
            }

            if (state.editModel.products == undefined) state.editModel.products = [];
            state.products = state.editModel.products;
            
            localStorage.booking = JSON.stringify(state.editModel);
        },
        new(state: BookingState, model: Booking) {
            let date = new Date();

            state.editModel = Object.assign({});
            state.editModel.customer_id = null;
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.editModel.branch_id = Util.abp.session.branchId;
            state.editModel.booking_date = Util.abp.clock.today();
            state.editModel.including_taxes = true;
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.editModel.currency_sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            state.editModel.exchange_rate = 1;
            state.editModel.discount_type = 'amount';
            state.editModel.subtotal = 0.00;
            state.editModel.subtotal_wt = 0.00;
            state.editModel.discounts = 0.00;
            state.editModel.taxes = 0.00;
            state.editModel.total = 0.00;
            state.editModel.total_wt = 0.00;
            state.editModel.total_paid = 0.00;
            state.editModel.balance = 0.00;
            state.editModel.number = 0;
            state.editModel.products = new Array<BookingProduct>();
            state.products = new Array<BookingProduct>();

            localStorage.booking = JSON.stringify(state.editModel);
        },
        view(state: BookingState, model: Booking) {
            state.viewModel = model;
        },
        updateAmount(state: BookingState) {
            state.editModel.subtotal = 0;
            state.editModel.subtotal_wt = 0;

            state.products.forEach(a => {
                state.editModel.subtotal += a.total;
                state.editModel.subtotal_wt += a.total_wt;
            });

            state.editModel.total = state.editModel.subtotal;
            state.editModel.total_wt = state.editModel.subtotal_wt;

            localStorage.booking = JSON.stringify(state.editModel);
        },
        setPayment(state: BookingState, item: any) {
            state.editModel.payment = item;
            localStorage.booking = JSON.stringify(state.editModel);
        },
        addProduct(state: BookingState, product: any) {
            let exist = state.products.filter((detail: any) => {
                return detail.product_id == product.id && detail.schedule == product.schedule;
            });

            if (exist.length == 0) {
                let detail = new BookingProduct();
                detail.currency_id = state.editModel.currency_id;
                detail.exchange_rate = state.editModel.exchange_rate;
                detail.duration = 1;

                detail.product_id = product.id;
                detail.schedule_id = product.schedule_id;
                detail.product_name = product.product_name;
                detail.schedule_name = product.schedule_name;                
                detail.schedule = product.schedule;
                detail.price_wt = product.price_wt;
                detail.price = Util.removeTaxes(detail.price_wt);
                detail.total = detail.price;
                detail.total_wt = detail.price_wt;
                detail.taxes = parseFloat((detail.total_wt - detail.total).toFixed(6));
                detail.product_price = detail.price_wt;
                detail.product = product;

                state.products.push(detail);
            }

            state.editModel.products = state.products;
            state.editModel.booking_date = product.booking_date;
            localStorage.booking = JSON.stringify(state.editModel);
        },
        deleteProduct(state: BookingState, index: number) {
            state.products.splice(index, 1);
            state.editModel.products = state.products;
            localStorage.booking = JSON.stringify(state.editModel);
        },
        reset(state: BookingState) {
            state.editModel = Object.assign({});
            state.products = [];
            localStorage.booking = JSON.stringify(state.editModel);
        },    
    });
}
const bookingModule = new BookingModule();
export default bookingModule;