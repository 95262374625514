import { Store, Module, ActionContext, MutationTree } from 'vuex'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface ContactState {
    loading: boolean;
}
class ContactModule implements Module<ContactState, any>{
    namespaced = true;
    state = {
        loading: false,
    }
    actions = {
        async send(context: ActionContext<ContactState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/contact', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
    };
    mutations = {
        
    };
}
const contactModule = new ContactModule();
export default contactModule;