import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import app from './modules/app'
import session from './modules/session'
import user from './modules/account/user' 
import address from './modules/account/address'
import dashboard from './modules/account/dashboard'
import contact from './modules/pages/contact'

import company from './modules/catalogs/company'
import product from './modules/catalogs/product'
import schedule from './modules/catalogs/schedule'

import booking from './modules/bookings/booking'

import country from './modules/preferences/country'
import city from './modules/preferences/city'
import generaltype from './modules/preferences/generaltype'
import generaltypevalue from './modules/preferences/generaltypevalue'
import configuration from './modules/preferences/configuration'
import notification from './modules/preferences/notification'
import conversation from './modules/preferences/conversation'
import customer from './modules/customer/customer'
import documenttype from './modules/customer/documenttype'

import paymentgateway from './modules/cashiers/paymentgateway'
import paymentmethod from './modules/cashiers/paymentmethod'

const store = new Vuex.Store({
    state: {
        //
    },
    mutations: {
        //
    },
    actions: {

    },
    modules: {
        app,
        session,
        user,
        address,
        dashboard,
        contact,

        company,
        product,
        schedule,

        booking,

        country,
        city,
        configuration,
        generaltype,
        generaltypevalue,
        notification,
        conversation,

        paymentgateway,
        paymentmethod,
        customer,
        documenttype,
    }
});

export default store;