import Entity from '../entity'

export default class Schedule extends Entity<number>{
    name: string;
    description: string;
    hour_start: string;
    hour_end: string;
}


