import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Schedule from '../../entities/catalogs/schedule'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface ScheduleState extends ListState<Schedule> {
    editModel: Schedule;
}
class ScheduleModule extends ListModule<ScheduleState, any, Schedule>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<Schedule>(),
        loading: false,
        editModel: new Schedule(),
        activeList: new Array<Schedule>(),
        path: 'schedules'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const scheduleModule = new ScheduleModule();
export default scheduleModule;