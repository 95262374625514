declare global {
    interface System {
        import(request: string): Promise<any>
    }
    var System: System
}
import Vue from 'vue';
import appconst from './appconst'
import { otherRouters } from '../router/router'

class Util{
    abp:any=window.abp;
    loadScript(url:string){
        var script=document.createElement('script');
        script.type="text/javascript";
        script.src=url;
        document.body.appendChild(script);
    }
    title(title: string) {
        let appname = this.abp.setting.get('COMPANY_NAME') != undefined ? this.abp.setting.get('COMPANY_NAME') : appconst.setting.appName;
        let page = this.abp.localization.localize(title, appconst.localization.defaultLocalizationSourceName);
        window.document.title = appname + (page != undefined ? ' - ' + page : '');
    }
    inOf(arr:Array<any>, targetArr:any) {
        let res = true;
        arr.forEach(item => {
            if (targetArr.indexOf(item) < 0) {
                res = false;
            }
        });
        return res;
    }
    oneOf(ele:any, targetArr:Array<any>) {
        if (targetArr.indexOf(ele) >= 0) {
            return true;
        } else {
            return false;
        }
    }
    showThisRoute (itAccess:any, currentAccess:any) {
        if (typeof itAccess === 'object' && Array.isArray(itAccess)) {
            return this.oneOf(currentAccess, itAccess);
        } else {
            return itAccess === currentAccess;
        }
    }
    getRouterObjByName (routers:Array<any>, name?:string):any {
        if (!name || !routers || !routers.length) {
            return null;
        }
        // debugger;
        let routerObj = null;
        for (let item of routers) {
            if (item.name === name) {
                return item;
            }
            routerObj = this.getRouterObjByName(item.children, name);
            if (routerObj) {
                return routerObj;
            }
        }
        return null;
    }
    toDefaultPage(routers: Array<any>, name: string | undefined, route: any, next: any) {
        let len = routers.length;
        let i = 0;
        let notHandle = true;
        while (i < len) {
            if (routers[i].name === name && routers[i].children && routers[i].redirect === undefined) {
                route.replace({
                    name: routers[i].children[0].name
                });
                notHandle = false;
                next();
                break;
            }
            i++;
        }
        if (notHandle) {
            next();
        }
    }
    toRoute(vm: Vue, name: string | undefined, param?: any) {
        let routerObj: any = {};
        routerObj.name = name; 
        routerObj.params = param;
        vm.$router.push(routerObj);
    }
    handleTitle (vm:any, item:any) {
        if (typeof item.meta.title === 'object') {
            return vm.$t(item.title.i18n);
        } else {
            return item.meta.title;
        }
    }
    setCurrentPath(vm: Vue, name?: string) { 
        let title = '';
        let isOtherRouter = false;
        vm.$store.state.app.routers.forEach((item: any) => {
            if (item.children != null) {
                if (item.children.length === 1) {
                    if (item.children[0].name === name) {
                        title = this.handleTitle(vm, item);
                        if (item.name === 'otherRouter') {
                            isOtherRouter = true;
                        }
                    }
                } else {
                    item.children.forEach((child: any) => {
                        if (child.name === name) {
                            title = this.handleTitle(vm, child);
                            if (item.name === 'otherRouter') {
                                isOtherRouter = true;
                            }
                        }
                    });
                }
            }
        });
        let currentPathArr = [];
        if (name === 'home') {
            currentPathArr = [
                {
                    meta:{title: this.handleTitle(vm, this.getRouterObjByName(vm.$store.state.app.routers, 'home'))},
                    path: 'main/home',
                    name: 'home'
                }
            ];
        } else if (((name as string).indexOf('index') >= 0 || isOtherRouter) && name !== 'home') {
            currentPathArr = [
                {
                    meta:{title: this.handleTitle(vm, this.getRouterObjByName(vm.$store.state.app.routers, 'home'))},
                    path: 'main/home',
                    name: 'home'
                },
                {
                    meta:{title: title},
                    path: '',
                    name: name
                }
            ];
        } else {
            let currentPathObj = vm.$store.state.app.routers.filter((item: any) => {
                if (item.children.length <= 0) {
                    return item.name === name;
                } else if (item.children.length == 1) {
                    return item.children[0].name === name||item.name===name;
                } else {
                    let i = 0;
                    let childArr = item.children;
                    let len = childArr.length;
                    while (i < len) {
                        if (childArr[i].name === name) {
                            return true;
                        }
                        i++;
                    }
                    return false;
                }
            })[0];
            if (currentPathObj == undefined && this.isCmsLink(name)) {
                let cms = this.getCmsLink(name);
                currentPathArr = [
                    {
                        meta: { title: cms.meta_title },
                        path: 'main/' + cms.link_rewrite,
                        name: cms.link_rewrite
                    }
                ];
            } else if (currentPathObj.children&&currentPathObj.children.length <= 1 && currentPathObj.name === 'home') {
                currentPathArr = [
                    {
                        meta: { title: 'Inicio'},
                        path: 'main/home',
                        name: 'home'
                    }
                ];
            } else if (currentPathObj.children&&currentPathObj.children.length <= 1 && currentPathObj.name !== 'home') {
                currentPathArr = [
                    {
                        meta: { title: 'Inicio'},
                        path: 'main/home',
                        name: 'home'
                    },
                    { 
                        meta:{title: currentPathObj.meta.title},
                        path: '',
                        name: name
                    }
                ];
            } else {
                let childObj = currentPathObj.children.filter((child:any) => {
                    return child.name === name;
                })[0];
                currentPathArr = [
                    {
                        meta:{title: 'Home'},
                        path: 'main/home',
                        name: 'home'
                    },
                    {
                        meta:{title: currentPathObj.meta.title},
                        path: '',
                        name: ''
                    },
                    {
                        meta:{title: childObj.meta.title},
                        path: currentPathObj.path + '/' + childObj.path,
                        name: name
                    }
                ];
            }
        }
        vm.$store.commit('app/setCurrentPath', currentPathArr);
    
        return currentPathArr;
    }
    openNewPage(vm: Vue, name: string | undefined, argu?: any, query?: any) {
        if (vm.$store == undefined) return;
        let pageOpenedList = vm.$store.state.app.pageOpenedList;
        let openedPageLen = pageOpenedList.length;
        let i = 0;
        let tagHasOpened = false;
        while (i < openedPageLen) {
            if (name === pageOpenedList[i].name) { // La página ya está abierta
                vm.$store.commit('app/pageOpenedList', {
                    index: i,
                    argu: argu,
                    query: query
                });
                tagHasOpened = true;
                break;
            }
            i++;
        }
        if (!tagHasOpened) {
            let tag = vm.$store.state.app.tagsList.filter((item: any) => {
                if (item == undefined) return false;
                if (item.children == undefined) {
                    return name === item.name;                    
                } else if (item.children.length == 0) {
                    return name === item.name;
                } else {
                    return name === item.children[0].name;
                }
            });
            tag = tag[0];
            if (tag) {
                tag = tag.children ? tag.children[0] : tag;
                if (argu) {
                    tag.argu = argu;
                }
                if (query) {
                    tag.query = query;
                }
                vm.$store.commit('app/increateTag', tag);
            }
        }
        vm.$store.commit('app/setCurrentPageName', name);
    }
    extend(...args:any[]) {
        let options, name, src, srcType, copy, copyType, copyIsArray, clone,
        target = args[0] || {},
        i = 1,
        length = args.length,
        deep = false;
        if ( typeof target === 'boolean') {
            deep = target;
            target = args[i] || {};
            i++;
        }
        if ( typeof target !== 'object' && typeof target !== 'function') {
            target = {};
        }
        if ( i === length) {
            target = this;
            i--;
        }
        for ( ; i < length; i++ ) {
            if ( (options = args[i]) !== null ) {
                for ( name in options ) {
                    src = target[name];
                    copy = options[name];
                    if ( target === copy ) {
                        continue;
                    }
                    srcType = Array.isArray(src) ? 'array': typeof src;
                    if ( deep && copy && ((copyIsArray = Array.isArray(copy)) || typeof copy === 'object')) {
                        if ( copyIsArray ) {
                            copyIsArray = false;
                            clone = src && srcType === 'array' ? src : [];
                        } else {
                            clone = src && srcType === 'object' ? src: {};
                        }
                        target[name] = this.extend(deep, clone, copy);
                    } else if ( copy !== undefined ) {
                        target[name] = copy;
                    }
                }
            }
        }
        return target;
    }
    buildRoutes(links: Array<Router>) {
        //let listResult: Array<Router> = [];
        let route: Router = {
            path: '/',
            name: 'main',
            permission: '',
            meta: { title: 'inicio' },
            component: () => import('../views/main.vue'),
            children: []
        };

        if (links != null) {
            links.forEach((item: any, index) => {
                route.children.push({
                    path: item.link_rewrite,
                    meta: { title: item.meta_title },
                    name: item.link_rewrite,
                    component: () => import('../views/pages/page.vue')
                });
            });
        }
        return [route];
    }
    getEntityValue(item, key) {
        var i_split = key.split(".");
        var itemValue = item;

        for (var c = 0; c < i_split.length; c++)
            if (itemValue != null) itemValue = itemValue[i_split[c]];

        return itemValue;
    }
    findByParam(list, param, value) {
        if (list == undefined) return [];
        let filterList = list.filter((child: any) => {
            return child != null && child[param] === value;
        });
        return filterList;
    }
    getByParam(vm: Vue, entity, param, value) {
        if (vm.$store.state[entity].activeList == undefined) return {};

        return vm.$store.state[entity].activeList.filter((child: any) => {
            return child != null && child[param] === value;
        })[0];
    }
    getEntity(vm: Vue, entity, id) {
        if (vm.$store.state[entity].activeList == undefined) return {};
        let item = vm.$store.state[entity].activeList.filter((child: any) => {
            return child.id === id; 
        })[0]; 
        return item;
    }
    getList(vm: Vue, entity) {
        return vm.$store.state[entity].activeList;
    }
    getEntityField(vm: Vue, entity, id, field) {
        let obj = this.getEntity(vm, entity, id);
        if (obj != undefined) return obj[field];
        return null;
    } 
    getEntityByParam(vm: Vue, entity, param, value) {
        if (vm.$store.state[entity].activeList == undefined) return {};
        let item = vm.$store.state[entity].activeList.filter((child: any) => {
            return child[param] == value;
        })[0];
        return item;
    } 
    getTypeValueByCode(vm: Vue, type, code) {
        return this.abp.list.get(type, code);
    }
    getTypeValueById(id) {
        return this.abp.list.getItem(id);
    }
    getUnits(vm: Vue, id) {
        let items = [];
        if (vm.$store.state.measureunit.activeList == undefined) return [];
        let unity = this.getEntity(vm, 'measureunit', id);

        items.push(unity.id);
        let parent_id = unity.parent_id;
        while (parent_id > 0) {
            let parent = this.getEntity(vm, 'measureunit', parent_id);
            items.push(parent_id);
            parent_id = parent.parent_id;
        }

        //let items = vm.$store.state.measureunit.activeList.filter((child: any) => {
        //    return child.parent_id == id || child.id == id;
        //});
        return items;
    }
    prepEntity(vm: Vue, items, data) {
        let newData = {id: 0};
        if (data.id != undefined) newData.id = data.id;
        items.forEach(item => {
            if (item.prop != undefined && typeof data[item.prop] == "object") {
                var prop = item.prop;
                if (prop.indexOf('_id') < 0) prop += '_id';
                if (data[item.prop] != undefined) {
                    if (item.prop.indexOf('address_') >= 0) {
                        if (newData['address'] == undefined) newData['address'] = { id: data.address_id };

                        if (item.type == 'ubigeo' && item.returnObject) {
                            newData['address']['department_id'] = data[item.prop]['department'];
                            newData['address']['district_id'] = data[item.prop]['district'];
                            newData['address']['province_id'] = data[item.prop]['province'];
                        } else
                            newData['address'][item.prop.replace('address_', '')] = data[item.prop].id;
                    } else if (Array.isArray(data[item.prop])) {
                        newData[item.prop] = data[item.prop];
                    } else                       
                        newData[prop] = data[item.prop].id;
                }
            } else if (item.prop != undefined) {
                if (item.prop.indexOf('address_') >= 0) {
                    if (newData['address'] == undefined) newData['address'] = {id: data.address_id};
                    newData['address'][item.prop.replace('address_','')] = data[item.prop];
                } else
                    newData[item.prop] = data[item.prop];
            }
            if (item.prefixProp != undefined) {
                if (typeof data[item.prefixProp] == "object") {
                    var prop = item.prefixProp;
                    if (prop.indexOf('_id') < 0) prop += '_id';
                    newData[prop] = data[item.prefixProp].id;
                } else {
                    newData[item.prefixProp] = data[item.prefixProp];
                }
            }
        });
        return newData;
    }
    setAddressProps(items, data) {
        let addrItems = [];
        if (data.address != null) {
            items.forEach(item => {
                if (item.prop != undefined && item.prop.indexOf('address_') >= 0) {
                    let prop = item.prop.replace('address_', '');
                    if (data.address[prop] != undefined)
                        data[item.prop] = data.address[prop];
                }
            });
        }
        return data;
    }
    findGeneralType(list, code, valueCode) {
        let gType = this.findByParam(list, 'code', code)[0];
        let obj = null;
        if (gType != null) {
            obj = this.findByParam(gType.values, 'code', valueCode)[0];
        }    
        return obj;
    }
    findByText(list, code, valueCode) {
        let gType = this.findByParam(list, 'code', code)[0];
        let obj = null;
        if (gType != null) {
            obj = this.findByParam(gType.values, 'code', valueCode)[0];
        }
        return obj;
    }
    addTaxes(price, rate = null, decimals = 6) {
        if (rate == null) rate = this.abp.setting.get('TAX_RATE');
        if (rate > 0) return parseFloat((price * (rate / 100 + 1)).toFixed(decimals));
        else return parseFloat(price);
    }
    removeTaxes(price, rate = null, decimals = 6) {
        if (rate == null) rate = this.abp.setting.get('TAX_RATE');
        if (rate > 0) return parseFloat((price / (rate / 100 + 1)).toFixed(decimals));
        else return parseFloat(price);
    }
    getListToExport(list, columns) {
        let dataList = { header: [], headerTitle: [], list: [] };

        columns.forEach(col => {
            if (col.value != undefined && col.value != 'action') {
                dataList.header.push(col.value);
                dataList.headerTitle.push(col.text);
            }
        });

        list.forEach(item => {
            let newItem = {};
            columns.forEach(col => {
                if (col.value != undefined && col.value != 'action') {
                    let value = this.getEntityValue(item, col.value);

                    if (col.type == 'percent')
                        value += ' %';
                    else if (col.type == 'price') {
                        let sign = (item['currency_sign'] != undefined ? item['currency_sign'] :
                            (item['currency'] != undefined ? item['currency'].sign : this.abp.setting.get('DEFAULT_CURRENCY_SIGN')));
                        value = sign + ' ' + parseFloat(value).toFixed(2);
                    } else if (col.type == 'date') {
                        value = value.substr(0, 10);
                    }
                    newItem[col.value] = value;
                }
            });

            dataList.list.push(newItem);
        });
        return dataList;
        
    }
    sortByParam(list, param, way) { 
        function compareAsc(a, b) {
            if (a[param] < b[param])
                return -1;
            if (a[param] > b[param])
                return 1;
            return 0;
        }
        function compareDesc(a, b) {
            if (a[param] > b[param])
                return -1;
            if (a[param] < b[param])
                return 1;
            return 0;
        }
        if (way == 'asc')
            return list.sort(compareAsc);
        else
            return list.sort(compareDesc);
    }
    isCmsLink(path: string) {
        let currentPathObj = this.abp.cmsPages.filter((item: any) => {
            return '/' + item.link_rewrite == path || item.link_rewrite == path;
        });

        return (currentPathObj.length > 0);
    } 
    getCmsLink(path: string) {
        let currentPathObj = this.abp.cmsPages.filter((item: any) => {
            return '/' + item.link_rewrite == path || item.link_rewrite == path;
        });

        return currentPathObj[0];
    } 
    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }
    today(vm: any, format?: number) {
        if (format == undefined) format = 1;
        if (format == 2)
            return vm.$moment().format('DD/MM/YYYY')
        else
            return vm.$moment().format('YYYY-MM-DD')
    }
    now(vm: any) {
        return vm.$moment().format('YYYY-MM-DD HH:mm:ss')
    }
    formatDate(vm: any, date) {
        return vm.$moment(date).format('YYYY-MM-DD HH:mm:ss')
    }
    firstDayOfMonth(vm: any, format?: number) {
        if (format == undefined) format = 1;
        if (format == 2)
            return '01' + vm.$moment().format('/MM/YYYY');
        else
            return vm.$moment().format('YYYY-MM-') + '01';        
    }
    rangeDates(vm: any, format?: number): Array<any> {
        if (format == undefined) format = 1; 
        return [this.firstDayOfMonth(vm, format), this.today(vm, format)];
    }
    isValidDate(date) {
        return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
    }
    lastDateOfMonth() {
        var today = new Date();
        return new Date(today.getFullYear(), today.getMonth() + 1, 0);
    }
    getInitials(string, first = false) {
        if (string == undefined) return '';
        var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
        
        if (!first && names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    }
    capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    zeroPadded(num) {
        // 4 --> 04
        return num < 10 ? `0${num}` : num;
    }
    formatMintues(minutes: number) {
        let seconds = minutes * 60;
        let hour = Math.floor(seconds / 3600);
        let minute = Math.floor((seconds / 60) % 60);
        let second = parseInt(String(seconds % 60));

        return `${this.zeroPadded(hour)}:${this.zeroPadded(minute)}:${this.zeroPadded(second)}`;
    }
    copyToClipboard(text) {
        if (typeof ((navigator as any).clipboard) == 'undefined') {
            var textArea = document.createElement("input");
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                document.execCommand('copy');
            } catch (err) {
                console.log(err);
            }

            document.body.removeChild(textArea)
            return;
        } else {
            (navigator as any).clipboard.writeText(text);
        }
    }
    replaceEmailContent(content: string, data) {
        let newContent = content;
        let list = this.abp.list.getList('TEMPLATE_FIELDS');
        list.forEach(item => {
            if (item.code == 'link') {
                newContent = newContent.replace('{{link}}', this.abp.setting.get('APP_LINK'));
            } else if (data[item.code] != undefined) {
                newContent = (newContent as any).replaceAll('{{' + item.code +'}}', data[item.code]);
            }
        });
        return newContent;
    }
    formatNumber(amount, decimals = 0) {
        if (amount == null) return '';
        if (decimals == 0)
            return new Intl.NumberFormat().format(amount);
        else
            return amount.toFixed(decimals).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
    addHours(start, hours) {
        let parts = start.split(':');
        return (parseInt(parts[0]) + parseInt(hours)) + ':' + parts[1];
    }
    setMessages(list) {
        let messages = [];
        list.forEach(mess => {
            let author = (mess.author_id == this.abp.session.userId ? `me` : mess.author_code);
            let data = {};
            if (mess.text != null) data['text'] = mess.text;
            if (mess.code != null) data['emoji'] = mess.code;
            if (mess.filename != null) {
                data['file'] = {
                    name: mess.filename,
                    url: mess.fileurl,
                };
            }
            messages.push({ type: mess.type, author: author, data: data });
        });
        return messages;
    }
}
const util=new Util();
export default util;