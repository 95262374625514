import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Company from '../../entities/catalogs/company'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface CompanyState extends ListState<Company>{
    editModel:Company;
    viewModel:Company;    
    categories:Array<any>;    
    id:number;    
}
class CompanyModule extends ListModule<CompanyState,any,Company>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Company>(),
        loading:false,
        editModel: new Company(),
        viewModel: new Company(),
        categories: new Array<any>(),
        id: 0,
        path: 'company'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<CompanyState, any>, payload: any) {
            context.state.loading = true;
            let params = {
                'ajax': 1,
                'action': 'list'
            };
            
            let reponse = await Ajax.get('?controller=company', { params: params }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            context.state.list = reponse.data;
            context.state.loading = false;
        },
        async get(context: ActionContext<CompanyState, any>, payload: any) {
            context.state.loading = true;
            let params = {
                'ajax': 1,
                'action': 'getData',
                'id_company': payload.id_company
            };

            let reponse = await Ajax.get('?controller=company', { params: params }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            context.state.list.push(reponse.data);
            context.state.loading = false;
            return reponse.data;
        },
        async getCategories(context: ActionContext<CompanyState, any>, payload: any) {
            if (context.state.categories.length > 0) return;

            context.state.loading = true;

            let reponse = await Ajax.get('?controller=company&ajax=1&action=getCategories').catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            context.state.categories = reponse.data;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        view(state: CompanyState, id: number) {
            state.id = id;
            state.viewModel = state.list.filter((child: any) => {
                return child.id == id;
            })[0];

            if (state.viewModel != undefined) {
                
                state.viewModel.hoursList = [];
                var array = [];

                if (state.viewModel != null) {
                    var d = new Date();
                    var weekday = new Array(7);
                    weekday[0] = "Domingo";
                    weekday[1] = "Lunes";
                    weekday[2] = "Martes";
                    weekday[3] = "Miercoles";
                    weekday[4] = "Jueves";
                    weekday[5] = "Viernes";
                    weekday[6] = "Sabado";

                    var day = d.getDay();
                    var idx = (day == 0 ? 6 : day - 1);

                    array.push({
                        current: false,
                        day: weekday[(day == 0 ? 6 : day - 1)],
                        hour: state.viewModel.hours[(idx == 0 ? 6 : idx - 1)]
                    });

                    array.push({
                        current: true,
                        day: weekday[day],
                        hour: state.viewModel.hours[idx]
                    });

                    array.push({
                        current: false,
                        day: weekday[(day == 6 ? 0 : day + 1)],
                        hour: state.viewModel.hours[(idx == 6 ? 0 : idx + 1)]
                    });
                }
                state.viewModel.hoursList = array;
            } else {
                state.viewModel = Object.assign({});
            }

        },
    });
}
const companyModule=new CompanyModule();
export default companyModule;