import Entity from '../entity'
export default class User extends Entity<number>{
    code: string;
    document: string;
    document_full: string;
    company: string;
    fullname: string;
    firstname: string;
    lastname: string;
    email: string;
    birthdate: string;
    active: boolean;
    gender_id: number;
    person_type_id: number;
    document_type_id: number;
    group_id: number;
    level_id: number;  

    group: any;
    document_type: any;
    address_full: string;
    username: string;
    avatar: string;
    auth_code: string;
    password: string;
    avatar_url: string;
    password2: string;
    terms: boolean;
}