declare global {
    interface RouterMeta {
        title: string;
    }
    interface Router {
        path: string;
        name: string;
        icon?: string;
        permission?: string;
        meta?: RouterMeta;
        component: any;
        children?: Array<Router>;
    }
    interface System {
        import(request: string): Promise<any>
    }
    var System: System
}
import home from '../views/home/home.vue'
import main from '../views/main.vue'
import Ajax from '../lib/ajax';


export const otherRouters: Router = {
    path: '/',
    name: 'main',
    permission: '',
    meta: { title: '' },
    component: main,
    children: [ 
        { path: '/', meta: { title: 'Inicio' }, name: 'home', component: () => import('../views/home/home.vue') },
        { path: 'page', meta: { title: 'Pagina' }, name: 'page', component: () => import('../views/pages/page.vue') }, 
        { path: 'contact', meta: { title: 'Contactanos' }, name: 'contact', component: () => import('../views/pages/contact.vue') },
        { path: 'about-us', meta: { title: 'Acerca de Nosotros' }, name: 'about-us', component: () => import('../views/pages/about-us.vue') },
        { path: 'terms', meta: { title: 'Terminos y Condiciones' }, name: 'terms', component: () => import('../views/pages/terms.vue') }, 
        { path: 'policy', meta: { title: 'Politica de Privacidad' }, name: 'policy', component: () => import('../views/pages/policy.vue') }, 
        { path: 'return', meta: { title: 'Politicas de cambio y devolucion' }, name: 'return', component: () => import('../views/pages/return.vue') }, 
        //{ path: 'tienda', meta: { title: 'Tienda' }, name: 'store', component: () => import('../views/catalogs/company/company.vue') },
        { path: 'booking', meta: { title: 'Reservas' }, name: 'booking', component: () => import('../views/bookings/booking.vue') },
        //{ path: 'product', meta: { title: 'Producto' }, name: 'product', component: () => import('../views/catalogs/product/product.vue') },
        { path: 'dashboard', meta: { title: 'Dashboard' }, name: 'dashboard', permission: '1', component: () => import('../views/account/home/dashboard.vue') },
        { path: 'perfil', meta: { title: 'Cuenta' }, name: 'profile', permission: '1', component: () => import('../views/account/profile/edit-profile.vue') },
        //{ path: 'account/promo-code', meta: { title: 'Promociones' }, name: 'promo', permission: '1', component: () => import('../views/account/booking/promo-codes.vue') },
        { path: 'tarjetas', meta: { title: 'Tarjetas de Credito' }, name: 'card', permission: '1', component: () => import('../views/account/card/cards.vue') },
        { path: 'reservas', meta: { title: 'Reservas' }, name: 'book', permission: '1', component: () => import('../views/account/booking/booking.vue') },
        { path: 'reservas/:id', meta: { title: 'Detalle de reserva' }, name: 'bookproduct', permission: '1', component: () => import('../views/account/booking/booking-detail.vue') },
        { path: 'checkout', meta: { title: 'Checkout' }, name: 'checkout', permission: '1', component: () => import('../views/bookings/cart/checkout.vue') }, 
    ]
}
export const appRouters: Array<Router> = [];

export const routers = [
    ...appRouters,
    otherRouters
];
