import Entity from '../entity'
import BookingProduct from '@/store/entities/bookings/bookingproduct';

export default class Booking extends Entity<number>{
    reference: string;
    serie: string;
    number: number;
    branch_id: number;
    customer_id: number;
    cashier_id: number;
    current_state_id: number;
    currency_id: number;
    sale_id: number;
    tax_id: number;
    tax_rate: number;
    including_taxes: boolean;
    discount_type: string;
    exchange_rate: number;
    subtotal: number;
    subtotal_wt: number;
    taxes: number;
    discounts: number;
    total: number;
    total_wt: number;
    total_paid: number;
    balance: number;
    booking_date: string;
    confirm_date: string;
    valid: boolean;
    invoiced: boolean;
    notes: string;
    products: Array<BookingProduct>;

    currency_sign: string;
    customer_name: string;
    nro: string;
    customer: any;
    currency: any;
    payment: any;
}


