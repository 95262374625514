import ajax from '../../lib/ajax';
import Util from '../../lib/util'
import {Store,Module,ActionContext} from 'vuex' 
interface SessionState{
    application: any,
    user: any,
    company: any,
    logged: boolean,
}
class SessionStore implements Module<SessionState,any>{
    namespaced=true;
    state={
        application: null,
        user: null,
        company: null,
        logged: false
    }
    actions={
        async init(content:ActionContext<SessionState,any>){
            let rep = await ajax.get('/getCurrentLoginInformations',{headers:{}});
            content.state.application = rep.data.result.application;
            if (rep.data.result && rep.data.result.user != null && rep.data.result.user.id > 0) {
                content.state.user = rep.data.result.user;
                Util.abp.session.userId = rep.data.result.user.id;
                content.state.logged = true;
            } else {
                Util.abp.session.userId = 0;
                content.state.logged = false;
            }
        },
        async logout(content: ActionContext<SessionState, any>) {
            await ajax.post('/logout', { headers: {} });
            content.state.user = null;
            content.state.logged = false;
            Util.abp.session.userId = 0;
            Util.abp.session.logged = false;
        }
    }
    mutations = {
        setUser(state: SessionState, user: any) {
            state.user = user;

            if (user != null && user.id != null && user.id > 0) {

                if (Util.abp.session == undefined) Util.abp.session = Object.assign({});
                Util.abp.session.userId = user.id;
            } else {
                Util.abp.session.userId = 0;
            }
        },

    }
}
const session=new SessionStore();
export default session;