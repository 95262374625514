import { Store, Module, ActionContext, MutationTree } from 'vuex'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface DashboardState {
    bonuses: any;
    ranges: any;
    categories: any;
    widgets: Array<any>;
    activities: any;
    loading: boolean;
    selectedCus: number;
}
class DashboardModule implements Module<DashboardState, any>{
    namespaced = true;
    state = {
        bonuses: [],
        ranges: [],
        categories: [],
        widgets: [],
        activities: {},
        loading: false,
        selectedCus: null,
    }
    actions = {
        async getDashBonus(context: ActionContext<DashboardState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/dash-bonus', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.bonuses = response.data.result.series;
            context.state.categories = response.data.result.categories;
        },
        async getDashRanges(context: ActionContext<DashboardState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/dash-ranges', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.ranges = response.data.result;
        },
        async getWidgetValues(context: ActionContext<DashboardState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/widgets', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.widgets = response.data.result;
            return context.state.widgets;
        },
        async getDashActivity(context: ActionContext<DashboardState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/activities', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.activities = response.data.result;
            return context.state.activities;
        },
    };
    mutations = {
        setCustomer(state: DashboardState, customer: number) {
            state.selectedCus = customer;
        },
    };
}
const dashboardModule = new DashboardModule();
export default dashboardModule;