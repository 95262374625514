import Entity from '../entity'

export default class Company extends Entity<number>{
    address: string;
    name : string;
    email : string;
    website: string;
    hours: Array<string>;
    hoursList: Array<any>;
    ruc: string;
    note: string;
    logo: string;
    favicon: string;
}


