import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Customer from '../../entities/customer/customer'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface CustomerState extends ListState<Customer> {
    editModel: Customer;

}
class CustomerModule extends ListModule<CustomerState, any, Customer>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<Customer>(),
        loading: false,
        editModel: new Customer(),
        activeList: new Array<Customer>(),
        path: 'customer/customers'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const customerModule = new CustomerModule();
export default customerModule;