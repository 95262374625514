import Entity from '../entity'

export default class PaymentGateway extends Entity<number>{
    customer_card_id?: number;
    payment_method_id: number;
    code: string;
    icon: string;
    image: string;
    name: string;
    phone: string;
    message: string;
    public_key: string;
    private_key: string;
    test: boolean;
    active: boolean;
}

