import Entity from '../entity'
import Product from '../catalogs/product';

export default class BookingProduct extends Entity<number>{
    booking_id: number;
    currency_id: number;
    product_id: number;
    schedule_id: number;
    sale_detail_id: number;
    exchange_rate: number;
    code: string;
    product_name: string;
    schedule_name: string;
    duration: number;
    price: number;
    price_wt: number;
    taxes: number;
    total: number;
    total_wt: number;
    schedule: string;
    finished: boolean;
    product_price: number;

    product: Product;
    currency_sign: string;
    including_taxes: boolean;
    seq: number;
}


