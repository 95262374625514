import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import User from '../../entities/account/user'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface UserState extends ListState<User> {
    editModel: User;

}
class UserModule extends ListModule<UserState, any, User>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<User>(),
        loading: false,
        editModel: new User(),
        path: 'auth'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getByReference(context: ActionContext<UserState, any>, payload: any) {
            if (context.state.loading) return context.state.list;
            context.state.loading = true;
            let response = await Ajax.get('/customers/ref', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async create(context: ActionContext<UserState, any>, payload: any) {
            context.state.loading = true;
            let data = payload.data;
            let password = data.password;

            let response = await Ajax.post('/customers', data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            if (response.data != undefined && response.data.result) {
                context.state.editModel = response.data.result;
                context.state.editModel.password = password;
            }
            
            context.state.loading = false;
            return response.data.result;
        },
        async update(context: ActionContext<UserState, any>, payload: any) {
            context.state.loading = true;

            let response = await Ajax.put('/customers/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async validateCode(context: ActionContext<UserState, any>, payload: any) {
            context.state.loading = true;
            let data = payload.data;

            let reponse = await Ajax.post('/validate-code', data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.editModel = data;
            context.state.loading = false;
            return reponse.data.result;
        },
        async resendCode(context: ActionContext<UserState, any>, payload: any) {
            context.state.loading = true;

            let reponse = await Ajax.post('/resend-code', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return reponse.data.result;
        },
        async sendResetCode(context: ActionContext<UserState, any>, payload: any) {
            context.state.loading = true;
            let data = payload.data;

            let reponse = await Ajax.post('/send-reset-code', data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return reponse.data.result;
        },
        async resetPassword(context: ActionContext<UserState, any>, payload: any) {
            context.state.loading = true;
            let data = payload.data;

            let reponse = await Ajax.post('/reset-password', data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return reponse.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: UserState, model: User) {
            state.editModel = Object.assign({});
            state.editModel.password = '';
            state.editModel.password2 = null;
        },
        edit(state: UserState, model: User) {
            state.editModel = model;
        }
    });
}
const userModule = new UserModule();
export default userModule;

