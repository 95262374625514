import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Product from '../../entities/catalogs/product'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import url from '../../../lib/url'

interface ProductState extends ListState<Product>{
    editModel:Product;
    activeList: Array<Product>;
}
class ProductModule extends ListModule<ProductState,any,Product>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 18,
        list: new Array<Product>(),
        loading:false,
        editModel:new Product(),
        activeList: new Array<Product>(),
        path: 'products',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<ProductState, any>, payload: any) {
            if (context.state.loading) return context.state.list;
            context.state.loading = true;
            let reponse = await Ajax.get('/products', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            }); 
            context.state.loading = false;
            context.state.totalCount = reponse.data.result.length;
            context.state.list = reponse.data.result;
            return context.state.list;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        view(state: ProductState, model: Product) {
            state.editModel = model;
        },
    });
}
const productModule=new ProductModule();
export default productModule;